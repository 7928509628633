import { useContext, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  // IconButton,
  Input,
  InputLeftAddon,
  InputRightElement,
  InputGroup,
  Stack,
  Spinner,
  Text,
  HStack,
  Select,
  Avatar,
  Center,
  useToast,
} from '@chakra-ui/react';
import {
  BsLock,
  // BsPencil
} from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import moment from 'moment';
import { CheckIcon } from '@chakra-ui/icons';
import { getAuth } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';

import { db, isDev, LINK, allProvinces } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';

function Profile() {
  const { t } = useTranslation('global');
  const dataContext = useContext(DataContext);
  const [loadingPhotoURL, setLoadingPhotoURL] = useState(false);
  const auth = getAuth();
  const toast = useToast();
  // const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const userRef = doc(db, 'users', auth.currentUser.uid);

  const {
    firstName,
    lastName,
    email,
    cell,
    address,
    photoURL,
    loginWith,
    createdAt,
    emailIsVerifiedAt,
    phoneIsSmsVerifiedAt,
    // phoneIsVoiceVerifiedAt,
    licenseSelfieVerifiedAt,
    kbaPassedAt,
    addressConfirmed,
    bankAccountAddedAt,
    stageOneVerified,
  } = dataContext.userData;

  // useEffect(
  //   () => {
  //     if (!stageOneVerified) {
  //       navigate('/');
  //     }
  //   }
  // );

  const onChangeAddressComponent = e => {
    updateDoc(userRef, {
      address: {
        ...address,
        [e.target.id]: e.target.value,
      },
    });
  };

  // const handleInputClick = () => {
  //   fileInputRef.current.click();
  // };

  const onChangeFileInput = async e => {
    setLoadingPhotoURL(true);
    // console.log('e.target.files', e.target.files);
    // console.log('e.target.files[0]', e.target.files[0]);
    try {
      // Store image in firebase
      const storeImage = async image => {
        return new Promise((resolve, reject) => {
          const storage = getStorage();
          const fileName = `profile-${Date.now()}`;

          const storageRef = ref(
            storage,
            'images/users/' + auth.currentUser.uid + '/' + fileName
          );

          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            'state_changed',
            snapshot => {
              // const progress =
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log('Upload is ' + progress + '% done');
              // switch (snapshot.state) {
              //   case 'paused':
              //     console.log('Upload is paused');
              //     break;
              //   case 'running':
              //     console.log('Upload is running');
              //     break;
              //   default:
              //     break;
              // }
            },
            error => {
              // console.log(error);
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                resolve(downloadURL);
              });
            }
          );
        });
      };

      const savedPhotoURL = await Promise.resolve(
        storeImage(e.target.files[0])
      ).catch(e => {
        // console.log('error', e);
        toast({
          title: t('couldNotUploadImage'),
          status: 'error',
          isClosable: true,
        });
        // if fail, return what was there before
        return photoURL;
      });

      // console.log('savedPhotoURL', savedPhotoURL);

      // setFormData(prevState => ({
      //   ...prevState,
      //   photoURL: savedPhotoURL,
      // }));
      // console.log('formDataToSave before update', formDataToSave);
      // Update in firestore
      await updateDoc(userRef, {
        photoURL: savedPhotoURL,
      });
      setLoadingPhotoURL(false);
      toast({
        description: t('profileUpdated'),
        status: 'info',
      });
      // compareNamePostalAddWarnings();
    } catch (error) {
      console.log(error);
      setLoadingPhotoURL(false);
      toast({
        title: t('couldNotUpdatePhotoUrl'),
        description: isDev ? JSON.stringify(error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const renderVerifications = () => {
    return (
      <div>
        <Divider borderColor="primary.500" borderWidth={1} my={8} />
        <HStack justify="space-between">
          <Text>
            {t('emailVerified')}{' '}
            <small style={{ color: 'grey' }}>
              {moment(
                emailIsVerifiedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </small>
          </Text>
          <CheckIcon color="blue.500" mx="3" />
        </HStack>

        <HStack justify="space-between">
          <Text>
            {t('smsVerified')}{' '}
            <small style={{ color: 'grey' }}>
              {moment(
                phoneIsSmsVerifiedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </small>
          </Text>
          <CheckIcon color="blue.500" mx="3" />
        </HStack>

        {/* <HStack justify="space-between">
          <Text>
            {t('voiceVerified')}{' '}
            <small style={{ color: 'grey' }}>
              {moment(
                phoneIsVoiceVerifiedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </small>
          </Text>
          <CheckIcon color="blue.500" mx="3" />
        </HStack> */}

        <HStack justify="space-between">
          <Text>
            {t('licenseVerified')}{' '}
            <small style={{ color: 'grey' }}>
              {moment(
                licenseSelfieVerifiedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </small>
          </Text>
          <CheckIcon color="blue.500" mx="3" />
        </HStack>

        <HStack justify="space-between">
          <Text>
            {t('identityVerified')}{' '}
            <small style={{ color: 'grey' }}>
              {moment(
                kbaPassedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </small>
          </Text>
          <CheckIcon color="blue.500" mx="3" />
        </HStack>


        {bankAccountAddedAt && (
          <HStack justify="space-between">
            <Text>
              {t('walletCreated')}{' '}
              <small style={{ color: 'grey' }}>
                {moment(
                  bankAccountAddedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY - h:mma')}
              </small>
            </Text>
            <CheckIcon color="blue.500" mx="3" />
          </HStack>
        )}

        <br />
        <Text>
          {t('withEEZESince')}{': '}
          {moment(createdAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
            'MMM D, YYYY'
          )}
        </Text>
        <br />
      </div>
    );
  };
  const renderAddress = () => {
    return (
      <>
        <Stack direction="row" w="100%" mt={2}>
          <Box flex="1" color={!address?.civic && 'red'}>
            {t('civic')}
          </Box>
          <Box flex="2" color={!address?.street && 'red'}>
            {t('street')}
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.civic || ''}
                isReadOnly={addressConfirmed}
                id="civic"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="2">
            <InputGroup>
              <Input
                type="text"
                value={address?.street || ''}
                isReadOnly={addressConfirmed}
                id="street"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>
        {address?.apt && (
          <>
            <Stack direction="row" w="100%">
              <Box flex="1" color="gray">
                {t('aptSuite')}
              </Box>
              <Box flex="2"></Box>
            </Stack>
            <Stack direction="row" w="100%">
              <Box flex="1">
                <Input
                  type="text"
                  value={address?.apt || ''}
                  id="apt"
                  onChange={onChangeAddressComponent}
                />
              </Box>
              <Box flex="2"></Box>
            </Stack>
          </>
        )}

        <Box flex="1" color={!address?.city && 'red'} mt={2}>
          {t('city')}
        </Box>

        <Box flex="1">
          <InputGroup>
            <Input
              type="text"
              value={address?.city || ''}
              id="city"
              _focus={
                addressConfirmed && {
                  borderColor: 'inherit',
                }
              }
              isReadOnly={addressConfirmed}
              onChange={onChangeAddressComponent}
            />
            {addressConfirmed && (
              <InputRightElement children={<BsLock color="lightgrey" />} />
            )}
          </InputGroup>
        </Box>

        <Stack direction="row" w="100%" mt={2}>
          <Box flex="1" color={!address?.province && 'red'}>
            {t('province')}
          </Box>
          <Box flex="1" color={!address?.postal && 'red'}>
            {t('postal')}
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">

          <InputGroup>
              {/* {formData?.province && formData?.province.length === 2 ? (
                <Text as="i">
                  {t('homeProvince')}: {formData?.province.toUpperCase()}
                </Text>
              ) : ( */}
              <Select
                id="province"
                mr={2}
                value={address?.province}
                onChange={onChangeAddressComponent}
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                disabled={addressConfirmed}
                // color={!formData.province ? 'red' : ''}
                // disabled={formData.warrantyPending || formData.warrantyName}
              >
                <option value="">
                  {/* {t('select')} {t('homeProvince')} */}
                </option>
                {allProvinces.map((prov, i) => (
                  <option key={i} value={Object.values(prov)}>
                    {Object.values(prov).map(value => value.toUpperCase())} -{' '}
                    {Object.keys(prov)}
                  </option>
                ))}
              </Select>
              {/* )}  */}
              {/* {!address.province && <span style={{ color: 'red' }}> *</span>} */}
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>

            {/* <InputGroup>
              <Input
                type="text"
                value={address?.province || ''}
                // value={address?.province.toUpperCase() || ''}
                isReadOnly={addressConfirmed}
                id="province"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup> */}


          </Box>


          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.postal || ''}
                isReadOnly={addressConfirmed}
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                id="postal"
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>
      </>
    );
  };

  const renderInformation = () => {
    return (
      <Flex justify={'center'}>
        <Stack p={2} spacing={2}>
          <input
            type="file"
            id="photoURL"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onChangeFileInput}
            accept="image/*"
            capture="user"
            // required
          />
          <Center>
            <FormControl w="94px">
              {loadingPhotoURL ? (
                <Flex
                  h="94"
                  w="full"
                  // bg="lightgrey"
                  justify="center"
                  align="center"
                >
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <>
                  <Avatar
                    size="xl"
                    borderWidth="1px"
                    borderColor="black"
                    src={photoURL}
                  />

                  {/* <Box position="absolute" right={0} bottom={0}>
                    <IconButton
                      bg="gray.700"
                      color="white"
                      size="sm"
                      onClick={handleInputClick}
                      variant="solid"
                      aria-label="Edit Profile Pic"
                      icon={<BsPencil />}
                    />
                  </Box> */}
                </>
              )}
            </FormControl>
          </Center>

          <HStack>
            <Box w="50%">
              <FormControl id="firstName">
                <FormLabel mb="1">{t('firstName')}</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    value={firstName}
                    readOnly
                    _focus={{
                      borderColor: 'inherit',
                    }}
                  />
                  <InputRightElement children={<BsLock color="lightgrey" />} />
                </InputGroup>
              </FormControl>
            </Box>
            <Box w="50%">
              <FormControl id="lastName">
                <FormLabel mb="1">{t('lastName')}</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    value={lastName}
                    readOnly
                    _focus={{
                      borderColor: 'inherit',
                    }}
                  />
                  <InputRightElement children={<BsLock color="lightgrey" />} />
                </InputGroup>
              </FormControl>
            </Box>
          </HStack>
          <FormControl id="email">
            <HStack justify="space-between">
              <FormLabel mb="1">{t('email')}</FormLabel>
              {loginWith === 'Google' && (
                <HStack>
                  <Text color="secondary.500" fontSize={'sm'}>
                    {t('loginCapital')}
                  </Text>
                  <FcGoogle />
                </HStack>
              )}
              {loginWith === 'Facebook' && (
                <HStack>
                  <Text color="secondary.500" fontSize={'sm'}>
                  {t('loginCapital')}
                  </Text>
                  <FaFacebook />
                </HStack>
              )}
            </HStack>
            <InputGroup>
              <>
                <Input
                  type="email"
                  value={email}
                  // color="grey"
                  readOnly
                  _focus={{
                    borderColor: 'inherit',
                  }}
                />
                <InputRightElement children={<BsLock color="lightgrey" />} />
              </>
            </InputGroup>
          </FormControl>
          <FormControl id="cell">
            <FormLabel mb="1">{t('cellPhone')}</FormLabel>
            <InputGroup>
              <InputLeftAddon children="+1" />
              <Input
                type="tel"
                value={cell}
                readOnly
                _focus={{
                  borderColor: 'inherit',
                }}
              />
              <InputRightElement children={<BsLock color="lightgrey" />} />
            </InputGroup>
          </FormControl>

          {renderAddress()}

          {stageOneVerified && renderVerifications()}
        </Stack>
      </Flex>
    );
  };

  return (
    <>
      <Header back />
      <AppBody>
        <Heading size="md" mb={0} pl={1}>
          {t('profile')}
        </Heading>

        {renderInformation()}

{stageOneVerified && (

  <Center m={4} align="center">
          <Text as="i">
            <a
              href={LINK.contactUrl}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
              >
              {t('contactUs')}
            </a>{' '}
              {t('toMakeAnyChangesToYourProfile')}
          </Text>
        </Center>
            )}

        <br/>
        <br/>
      </AppBody>
    </>
  );
}

export default Profile;
