import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Image,
  Text,
  Link,
  Alert,
  AlertDescription,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  BsChevronLeft,
  BsPower,
  BsQuestionCircle,
  BsPerson,
  BsHouse,
} from 'react-icons/bs';
import { AiOutlineDollar } from 'react-icons/ai';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, getDoc, updateDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import HelpCarousel from '../components/HelpCarousel';
// import DataContext from '../context/DataContext';
import SwitchLanguage from '../components/SwitchLanguage';
import googleImage from '../assets/google_icon.png';
import { isDev, deployed, LINK } from '../constants';
import { db } from '../constants';

function Header(props) {
  const { t } = useTranslation('global');
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();
  const [queryParameters] = useSearchParams();
  const auth = getAuth();

  const toast = useToast();
  // const auth = getAuth();
  // const params = useParams();
  const navigate = useNavigate();
  const ref = queryParameters.get('ref');
  const refSlug = ref ? '?ref=' + ref : '';
  const andRefSlug = ref ? '&ref=' + ref : '';

  // need to useEffect here, cant use DataContext because
  // this is not a child not wrapped in App.js
  useEffect(() => {
    if (!auth.currentUser) return;

    const unsubscribe = onSnapshot(
      doc(db, 'users', auth.currentUser.uid),
      doc => {
        setUserData(doc.data());
      }
    );
    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const docRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('user in onAuthStateChanged', user);
          // console.log('user found user', auth.currentUser);
          setUserData(docSnap.data());
          setLoggedIn(true);
        } else {
          // console.log("first login")
          setLoggedIn(true);
        }
        // setCurrentUser(user);
      } else {
        // console.log('there is no user');
        setUserData(null);
        setLoggedIn(false);
      }
      //   setIsLoading(false);
      setCheckingStatus(false);
    });
    return unsubscribe;
  }, [auth]);

  const onLogout = () => {
    if (
      !window.confirm(t('logoutAsUserAreYouSure', { user: userData.email }))
    ) {
      return;
    }
    // console.log('auth', auth);
    // auth.signOut();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({ description: t('loggedOut') });
        navigate('/login');
        return;
      })
      .catch(error => {
        console.error(error);
        toast({ description: t('logOutError') });
        // An error happened.
      });
  };

  const enableFullAccess = () => {
    updateDoc(doc(db, 'users', auth.currentUser.uid), {
      warrantySignupOnly: false,
    });
  };
  // const bgColor = useColorModeValue('gray.100', 'gray.900');

  if (checkingStatus) {
    return (
      <>
        <Box pt={3} px={2} h={'60px'}>
          <Button
            leftIcon={props?.back && <BsChevronLeft />}
            // pl={1}
            pl={props?.back ? 1 : null}
            onClick={() => navigate('/')}
            variant="ghost"
          >
            <Text
              fontSize="lg"
              borderBottom="2px"
              borderBottomColor="primary.500"
            >
              {props?.back ? t('backCapital') : 'EEZE'}
            </Text>
          </Button>
        </Box>
      </>
    );
  }

  // not logged in
  if (!loggedIn) {
    return (
      <>
        <Box
          // bg={bgColor}
          px={2}
          h={'60px'}
          borderTop={isDev ? '2px' : '0px'}
          borderTopColor="primary.500"
        >
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            {/* LEFT */}
            <Box>
              <Button
              pl={props?.back ? 1 : null}
              onClick={() => navigate('/')} variant="ghost">
                <Text
                  fontSize="lg"
                  borderBottom="2px"
                  borderBottomColor="primary.500"
                >
                  EEZE
                </Text>
              </Button>
            </Box>

            {/* RIGHT */}
            <Flex alignItems={'center'}>
              <Button
                colorScheme="primary"
                // size="sm"
                variant="ghost"
                onClick={() =>
                  navigate(
                    params?.listingUid
                      ? `/login?uid=${params.listingUid + andRefSlug}`
                      : '/login' + refSlug
                  )
                }
              >
                {t('login')}
              </Button>
              <Button
                colorScheme="primary"
                // size="sm"
                onClick={() =>
                  navigate(
                    params?.listingUid
                      ? `/signup?uid=${params.listingUid + andRefSlug}`
                      : '/signup' + refSlug
                  )
                }
              >
                {t('signup')}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </>
    );
  }

  // logged in
  return (
    <>
      <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <HelpCarousel />
          <ModalFooter>
            <Button
              onClick={onClose}
              w="full"
              colorScheme="primary"
              variant="outline"
            >
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box px={2} h={'60px'}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          {/* LEFT */}

          <Button
            leftIcon={props?.back && <BsChevronLeft />}
            pl={props?.back ? 1 : null}
            onClick={() => navigate('/')}
            variant="ghost"
          >
            <Text
              fontSize="lg"
              borderBottom="2px"
              borderBottomColor="primary.500"
            >
              {props?.back ? t('backCapital') : 'EEZE'}
            </Text>
          </Button>

          {/* MIDDLE */}

          {/* <Box>
            <Button onClick={() => navigate('/')} variant="ghost" as={'a'}>
              <Image
                src={logoImage}
                alt="Logo"
                boxSize="40px"
                objectFit="cover"
              />
            </Button>
          </Box>

          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 70,
              height: '60px',
              marginTop: '16px',
            }}
          >
            <IconButton
              variant="ghost"
              colorScheme="pink"
              aria-label="Help"
              fontSize="30px"
              onClick={onOpen}
              icon={<BsQuestionCircle />}
            />
          </div> */}

          {/* RIGHT */}

          <Menu>
            <MenuButton
              as={IconButton}
              style={{
                borderColor: 'primary.500',
                color: 'primary.500',
                backgroundColor: 'white',
              }}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList zIndex={10}>
              <SwitchLanguage />
              <Center mt={4}>
                <Text fontSize="xs" color="grey">
                  {t('loggedInAs')}
                </Text>
              </Center>
              <Center>
                <Text align="center">
                  {userData?.firstName} {userData?.lastName}
                </Text>
              </Center>
              <Center mb={4}>
                <Text align="center" size="xs" mx={5}>
                  {userData?.email}
                </Text>
              </Center>

              <Divider borderWidth={1} />
              <MenuItem>
                <Button
                  onClick={() => navigate('/')}
                  variant="ghost"
                  w="full"
                  as={'a'}
                  justifyContent="flex-start"
                  leftIcon={<BsHouse />}
                >
                  {t('dashboard')}
                </Button>
              </MenuItem>
              {!userData?.warrantySignupOnly && (
                <>
                  {userData.stageOneVerified ? (
                    <MenuItem>
                      <Button
                        onClick={() => navigate('/wallet')}
                        w="full"
                        variant="ghost"
                        justifyContent="flex-start"
                        as={'a'}
                        leftIcon={<AiOutlineDollar />}
                      >
                        {t('wallet')}
                      </Button>
                    </MenuItem>
                  ) : (
                    <Button
                      w="full"
                      pl="7"
                      variant="ghost"
                      justifyContent="flex-start"
                      as={'a'}
                      leftIcon={<AiOutlineDollar />}
                      disabled
                    >
                      {t('wallet')}
                    </Button>
                  )}
                </>
              )}
              {/* {userData.stageOneVerified ? ( */}
              <MenuItem>
                <Button
                  onClick={() => navigate('/profile')}
                  w="full"
                  variant="ghost"
                  justifyContent="flex-start"
                  as={'a'}
                  leftIcon={<BsPerson />}
                >
                  {t('profile')}
                </Button>
              </MenuItem>
              {/* ) : (
                <Button
                  w="full"
                  pl="7"
                  variant="ghost"
                  justifyContent="flex-start"
                  as={'a'}
                  leftIcon={<BsPerson />}
                  disabled
                >
                  {t('profile')}
                </Button>
              )} */}
              <MenuItem>
                <Button
                  onClick={onOpen}
                  w="full"
                  variant="ghost"
                  justifyContent="flex-start"
                  as={'a'}
                  leftIcon={<BsQuestionCircle />}
                >
                  {t('help')}
                </Button>
              </MenuItem>
              <Divider borderWidth={1} />
              <MenuItem>
                <Button
                  onClick={onLogout}
                  w="full"
                  variant="ghost"
                  justifyContent="flex-start"
                  as={'a'}
                  leftIcon={<BsPower />}
                >
                  <Text mr={3}>{t('logOut')}</Text>
                </Button>
              </MenuItem>
              {userData?.warrantySignupOnly && (
                <Alert
                  status="warning"
                  // variant="left-accent"
                  flexDirection="column"
                  maxW="xs"
                  // alignItems="center"
                  // justifyContent="center"
                  textAlign="center"
                  mt={10}
                  // ml={2}
                >
                  <AlertDescription>
                    {/* <Text> */}
                    {t('EEZEIsAFreePlatformToProtectYourselfFromFraud')}
                    {/* </Text> */}
                  </AlertDescription>
                  <Button
                    mt={5}
                    w="full"
                    size="sm"
                    // variant="outline"
                    colorScheme="blackAlpha"
                    onClick={enableFullAccess}
                  >
                    {t('enableFullAccess')}
                  </Button>
                </Alert>
              )}

              <Center mt="70px">
                <Button
                  size="sm"
                  onClick={() => window.open(LINK.googleReview, '_blank')}
                  variant="outline"
                >
                  <Text>{t('leaveAReview')}</Text>
                  <Image
                    src={googleImage}
                    alt="Logo"
                    boxSize="30px"
                    objectFit="cover"
                  />
                </Button>
              </Center>
              <Center mt="50px">
                <Text fontSize="sm">
                  &copy; {1900 + new Date().getYear()}{' '}
                  <Link href="https://eeze.co" isExternal color="primary.800">
                    EEZE Inc.
                  </Link>
                </Text>
              </Center>
              <Center>
                <Text fontSize="sm">{t('allRightsReserved')}</Text>
              </Center>
              {isDev && (
                <Center>
                  <Text fontSize="xs" color="gray" as="i">
                    DEV {deployed}
                  </Text>
                </Center>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </>
  );
}

export default Header;
