import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  Grid,
  ListIcon,
  VStack,
  Button,
  Box,
  Container,
  Center,
  Flex,
  List,
  ListItem,
  Heading,
  HStack,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react';
import { BsHouse } from 'react-icons/bs';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { getDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { db } from '../constants';
import SpinnerEEZEPage from '../components/SpinnerEEZEPage';
import Header from '../components/Header';
import AppBody from '../components/AppBody';
import DataContext from '../context/DataContext';
import logoImage from '../assets/shield256.png';

function User() {
  const [user, setUser] = useState(null);
  const [listing, setListing] = useState({});
  const [loading, setLoading] = useState(true);
  // const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();
  const { userData } = useContext(DataContext);

  // const ref = queryParameters.get('ref');
  // const refSlug = ref ? '&ref=' + ref : '';

  useEffect(() => {
    const fetchUser = async () => {
      const docRef = doc(db, 'users', params?.userUid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // const user = docSnap.data();
        setUser(docSnap.data());
        setLoading(false);
      } else {
        window.alert('User does not exist');
        navigate('/');
      }
    };

    fetchUser();
  }, [navigate, params.userUid]);

  if (loading) {
    return <SpinnerEEZEPage />;
  }

  const contactSeller = () => {
    if (!userData.stageOneVerified) {
      window.alert('You must update your Profile before contacting the Seller');
      return;
    }
    navigate('/connect/' + listing?.uid + '/' + userData.uid);
  };
  // console.log(user)
  let photo, name, location;

  if (user) {
    if (user?.address && Object.keys(user?.address).length !== 0) {
      photo = user?.photoURL;
      name = user?.firstName + ' ' + user?.lastName;
      location =
        user.address?.city + ', ' + user?.address?.province.toUpperCase();
      // console.log(userData)
    } else {
      name = user?.firstName + ' ' + user?.lastName;
    }
  }
  return (
    <>
      <Header />
      <AppBody>
        <Center mb={2}>
          <HStack textAlign="center">
            <Image
              src={logoImage}
              alt="Logo"
              boxSize="60px"
              objectFit="cover"
            />
            <Heading size="md">EEZE Verified</Heading>
          </HStack>
        </Center>
        <Container maxW="container.xl">
          <Card maxW="3xl" mx="auto" shadow="lg" variant="outline">
            <CardHeader>
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  {/* <Box
                bg="gray.200"
                w={16}
                h={16}
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={4}
              >
                <InfoIcon boxSize={8} color="gray.500" />
              </Box> */}
                  <Avatar
                    src={photo}
                    borderWidth="2px"
                    borderColor={'#5e65a5'}
                  />
                  <Box ml={2}>
                    <Heading size="lg">{name}</Heading>
                    <Text fontSize="sm" color="gray.500">
                      {location}
                    </Text>
                  </Box>
                </Flex>
                <VStack align="flex-end">
                  {/* <Text fontWeight="bold">EEZE id 123</Text> */}
                  <Button size="sm" variant="outline" colorScheme="primary">
                    Share
                  </Button>
                </VStack>
              </Flex>
            </CardHeader>
            <CardBody>
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                gap={6}
              >
                <Box>
                  <Heading size="md" mb={2}>
                    Verified Information
                  </Heading>
                  <List spacing={2}>
                    {[
                      'Cell: passcode verified',
                      'Email: passcode verified',
                      'Identity vs photo ID',
                      'Liveness detection',
                      'Equifax Credit bureau check',
                      "Driver's license",
                    ].map(item => (
                      <ListItem key={item} fontSize="sm">
                        <ListIcon as={CheckCircleIcon} color="primary.500" />
                        {item}
                      </ListItem>
                    ))}
                  </List>
                  <Text fontSize="sm" mt={4}>
                    Date Verified: Oct 24, 2024
                  </Text>
                </Box>
                {/* <Box>
                  <Heading size="md" mb={2}>
                    Wallet
                  </Heading>
                  <Text fontSize="2xl" fontWeight="bold">
                    $0
                  </Text>
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme="primary"
                    mt={2}
                  >
                    + Add Banking
                  </Button>
                </Box> */}
              </Grid>
              {/* <Box mt={8}>
                <Heading size="md" mb={2}>
                  Meet up
                </Heading>
                <Box
                  bg="gray.100"
                  h={40}
                  borderRadius="md"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="sm" color="gray.500">
                    Map with circle km range
                  </Text>
                </Box>
                <Text fontSize="sm" mt={2}>
                  I'm willing to meet...
                </Text>
              </Box> */}
              {/* <Box mt={8}>
                <Heading size="md" mb={2}>
                  Vehicle Card
                </Heading>
                <Card variant="outline">
                  <CardBody>
                    <Flex>
                      <Box
                        bg="gray.200"
                        w={24}
                        h={24}
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={4}
                      >
                        <Text fontSize="xs" color="gray.500">
                          Car pic
                        </Text>
                      </Box>
                      <Stack spacing={1}>
                        <Text fontWeight="bold">Year Make Model</Text>
                        <Text fontSize="sm" color="gray.500">
                          Financing option $500/month based on province
                        </Text>
                        <Text fontSize="sm" mt={2}>
                          What is checked
                        </Text>
                      </Stack>
                    </Flex>
                  </CardBody>
                  <CardFooter justify="space-between">
                    <Button size="sm" variant="outline">
                      Contact seller
                    </Button>
                    <Button size="sm" variant="outline">
                      View trader listing
                    </Button>
                  </CardFooter>
                </Card>
              </Box> */}
            </CardBody>
          </Card>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container
          position="fixed"
          bottom="0"
          bgColor="white"
          py="5"
          left="0"
          // mx="auto"
          borderWidth="1px"
          maxW="full"
          centerContent
        >
          {!auth.currentUser ? (
            <Box px={5}>
              <Center mb={2}>
                <Text color={'primary.800'} textAlign="center">
                  Login / Sign up to contact this user
                </Text>
              </Center>
              <Stack direction="row" w="full">
                <Button
                  flex="1"
                  onClick={() => navigate('/login')}
                  colorScheme="primary"
                  variant="outline"
                >
                  Login
                </Button>
                <Button
                  flex="1"
                  onClick={() => navigate('/signup')}
                  colorScheme="primary"
                >
                  Sign Up
                </Button>
              </Stack>
            </Box>
          ) : (
            <>
              {auth.currentUser.uid !== user.uid ? (
                <Box px={5}>
                  <HStack>
                    <Button
                      size={'lg'}
                      colorScheme="primary"
                      onClick={contactSeller}
                      // disabled={!listingIsActive}
                    >
                      BUY / OFFER
                    </Button>
                    <Box flex={1}>
                      <Text align="center">
                        Accepting credit card payments!
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              ) : (
                <>
                  {/* <ButtonGroup px={2}> */}
                    <Button
                      colorScheme="primary"
                      // variant="outline"
                      // leftIcon={<BsChevronLeft />}
                      leftIcon={<BsHouse />}
                      onClick={() => navigate('/')}
                    >
                      {/* <BsHouse mr={2}/>  */}
                      Dashboard
                    </Button>
                  {/* </ButtonGroup> */}
                </>
              )}
            </>
          )}
        </Container>
      </AppBody>
    </>
  );
}

export default User;
