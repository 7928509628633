import { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Card,
  CardBody,
  Flex,
  FormLabel,
  FormControl,
  Heading,
  Image,
  Input,
  InputRightElement,
  InputGroup,
  Stack,
  Text,
  HStack,
  Center,
  Checkbox,
  Divider,
  Select,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Textarea,
  Spinner,
  // Progress,
  PinInput,
  PinInputField,
  useToast,
  VStack,
  Stepper,
  Step,
  StepIndicator,
  StepSeparator,
  StepNumber,
  StepIcon,
  StepStatus,
  // useSteps,
} from '@chakra-ui/react';
import {
  BsLock,
  BsShieldShaded,
  BsHouse,
  BsChevronRight,
} from 'react-icons/bs';
import moment from 'moment';
import { CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { getAuth, updateEmail } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore';
import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';

import {
  db,
  TWILIO_START_VERIFY,
  TWILIO_VERIFY_OTP,
  isDev,
  LINK,
  showUserBadge,
  kountInsteadOfBanking,
  BANKING_IFRAME,
  allProvinces,
  eligibleWarrantyProvinces,
} from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
// import FilePicker from '../components/FilePicker';
import CreditQuestionnaire from '../components/CreditQuestionnaire';
import checkCell from '../services/checkCell';
import generateLicenseSelfieCheckURL from '../services/getLicenseVerifyURL';
import getRandomUserPhoto from '../services/getRandomUserPhoto';
import sendEmailPasscode from '../services/sendEmailPasscode';
import sendEmailToAdmin from '../services/sendEmailToAdmin';
import stageTwoVerifyUser from '../services/stageTwoVerifyUser';
import checkMark from '../assets/animat-checkmark.gif';

function Verify() {
  const { t } = useTranslation('global');
  const dataContext = useContext(DataContext);
  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const tab1Ref = useRef(null);
  const tab2Ref = useRef(null);
  const tab3Ref = useRef(null);
  const userRef = doc(db, 'users', auth.currentUser.uid);

  const [tabIndex, setTabIndex] = useState(0);
  const steps = [
    { title: '1', description: '' },
    { title: '2', description: '' },
    { title: '3', description: '' },
  ];
  const { userData } = dataContext;
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [smsPINSent, setSmsPINSent] = useState(false);
  const [sendSmsPINText, setSendSmsPINText] = useState('');
  const [fetchingSmsPIN, setFetchingSmsPIN] = useState(false);
  const [consentIsChecked, setConsentIsChecked] = useState(false);
  const [fetchingLicenseURL, setFetchingLicenseURL] = useState(false);
  const [fetchingBankingIframe, setFetchingBankingIframe] = useState(false);

  const {
    firstName,
    lastName,
    email,
    cell,
    address,
    emailPasscode,
    emailIsVerified,
    emailIsVerifiedAt,
    phoneIsSmsVerified,
    phoneIsSmsVerifiedAt,
    // phoneIsVoiceVerified,
    // phoneIsVoiceVerifiedAt,
    licenseSelfieVerifiedURL,
    licenseSelfieVerified,
    licenseSelfieVerifiedAt,
    licenseSelfieError,
    licenseSelfieErrorComments,
    addressConfirmed,
    bankAccountIframe,
    bankAccountAdded,
    bankAccountAddedAt,
    bankAccountError,
    bankAccountErrorComments,
    bankSkipped,
    kbaPassed,
    kbaPassedAt,
    kbaError,
    kbaErrorComments,
    stageOneVerified,
    verificationConsentGiven,
    // stageTwoVerified,
    stageTwoWarnings,
    stageTwoWarningsComments,
    uid,
  } = userData;

  const [formData, setFormData] = useState({
    cell,
    licenseSelfieErrorComments: licenseSelfieErrorComments || '',
    kbaErrorComments: kbaErrorComments || '',
    // bankAccountErrorComments: bankAccountErrorComments || '',
  });
  // console.log('user', dataContext.userData)

  useEffect(() => {
    const tab = Number(searchParams.get('tab'));
    if (Number(tab)) {
      // setDefaultTab(Number(tab));
      setTabIndex(Number(tab));
    }
  }, [searchParams]);

  // status of each tab
  let tab1Complete = false;
  let tab2Complete = false;
  let tab3Complete = false;

  if (emailIsVerified && phoneIsSmsVerified) {
    tab1Complete = true;
  }

  if (licenseSelfieVerified && addressConfirmed) {
    tab2Complete = true;
  }

  if (kbaPassedAt && kbaPassed === true) {
    tab3Complete = true;
  }

  if (bankAccountAdded) {
    tab3Complete = true;
  }

  if (bankSkipped) {
    tab3Complete = true;
  }

  // const handleToTab1Click = () => {
  //   tab1Ref.current.click();
  // };
  const handleToTab2Click = () => {
    tab2Ref.current.click();
  };
  const handleToTab3Click = () => {
    tab3Ref.current.click();
  };

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  // ========== dev approve functions ==========
  const onClickManualApproveEmail = async () => {
    // skip sending email verification in dev
    if (!isDev) return;

    await updateDoc(userRef, {
      emailIsVerified: true,
      emailIsVerifiedAt: new Date().toString(),
    });
    toast({
      description: t('emailVerificationComplete'),
      status: 'info',
      isClosable: true,
    });
  };

  const onClickManualApproveSMS = async () => {
    // skip sending email verification in dev
    if (!isDev) return;

    await updateDoc(userRef, {
      phoneIsSmsVerified: true,
      phoneIsSmsVerifiedAt: new Date().toString(),
    });
    toast({
      description: t('smsVerificationComplete'),
      status: 'info',
      isClosable: true,
    });
  };

  const onClickManualApproveLicenseSelfie = async () => {
    if (!isDev) return;

    const userPhoto = getRandomUserPhoto();

    await updateDoc(userRef, {
      addressConfirmed: false,
      address: {
        civic: '123',
        street: 'Main Street',
        city: 'Toronto',
        province: 'on',
        postal: 'M5W 1E6',
        country: 'ca',
      },
      photoURL: userPhoto,
      licenseSelfieVerified: true,
      licenseSelfieVerifiedAt: new Date().toString(),
      licenseSelfieVerifiedURL: '',
      licenseSelfieError: '',
      firstName,
      lastName,
      // data format from persona
      // licenseSelfieData: {
      //   selfiePhotoUrl: userPhoto,
      //   firstName,
      //   lastName,
      //   bday: '2000-01-01',
      //   addressStreet1: '123 Main Street',
      //   addressStreet2: '',
      //   addressCity: 'Toronto',
      //   addressProvince: 'Ontario',
      //   postal: 'M5W 1E6',
      //   country: 'CA',
      //   licenseId: '',
      //   licenseId2: 'D6101-12345-32111',
      //   licenseExpiration: '',
      //   licensePhotoFront:
      //     'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2F1%20-%20license.png?alt=media&token=08066a29-0e43-45fb-9b28-ef765bf06f2b',
      //   licensePhotoBack: '',
      //   licensePicCropped:
      //     'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Flicense-cropped.png?alt=media&token=0bf29721-a6ab-4b3d-b85b-60c685be2742',
      //   device: {},
      // },
      // docupass deprecated data
      // licenseSelfieData: {
      //   success: true,
      //   faceimage: [
      //     {
      //       type: 'jpg',
      //       url: userPhoto,
      //     },
      //   ],
      //   data: {
      //     issuerOrg_region_full: 'Quebec',
      //     nationality_full: 'Canada',
      //     firstName: firstName,
      //     lastName: lastName,
      //     address1: '123 Main Street',
      //     address2: 'Montreal',
      //     postcode: 'H3N 1N6',
      //   },
      // },
      // updated docupass
      licenseSelfieData: {
        id: 'f5eda80c756245b09f9b10aa008bbc6d',
        data: {
          // flattened data
        },
        outputImage: {
          front:
            '0c4553cad732b9638c2ef13b14ea2b019cb939a74fb343378276583b74ad055d',
          back: 'a51306c75511b737fd43a429a91e9c4bcb7a9fb5ec8fd71dc4321aeb9353066c',
          face: '1605ce6db0c26971baa10335a62e6206b5c20d7a87d0d8080daf99a9cab4324c',
        },
        outputFile: [
          {
            name: 'Transaction Audit Report',
            fileName:
              'transaction-audit-report_ipCNSbyNjGoXt6ixUQdb4rfbE29yJQdQ.pdf',
            fileUrl:
              'https://api2-us2.idanalyzer.com/filevault/transaction-audit-report_ipCNSbyNjGoXt6ixUQdb4rfbE29yJQdQ.pdf',
          },
          {
            name: 'Face Audit Report',
            fileName: 'face-audit-report_q2fmK6NGQLhZXTQhNM4jvQxLhdAMnhFx.pdf',
            fileUrl:
              'https://api2-us2.idanalyzer.com/filevault/face-audit-report_q2fmK6NGQLhZXTQhNM4jvQxLhdAMnhFx.pdf',
          },
        ],
        profileId: '4ad077d6afb847bcb643de01abb9d459',
        reviewScore: 0,
        rejectScore: 0,
        decision: 'accept',
        customData: 'fuqWb0xYlpOLvNPk6gjwghpDSrA2',
        createdAt: 1732888065,
        updatedAt: 1732888087,
        docupass: 'USHMEV14CBL3833B7IKH74VD5N',
      },
    });
  };

  const onClickManualApproveBanking = async () => {
    // skip sending email verification in dev
    if (!isDev) return;

    await updateDoc(userRef, {
      bankAccountAdded: true,
      bankAccountAddedAt: new Date().toString(),
      bankAccountError: '',
      bank: {
        cardid: '05b9d2cc-dce8-4036-b453-866d1f6254d6',
        requestid: 'fb7ad5be-5701-45df-bcba-b7239cc8b155',
        userid: '182b7730-1298-4c1f-9a34-eeb7b594db84',
      },
    });
    toast({
      description: 'Banking complete',
      status: 'success',
    });
  };

  const onClickManualApproveKBA = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      kbaPassed: true,
      kbaPassedAt: new Date().toString(),
      kbaError: '',
      stageOneVerified: true,
      stageOneVerifiedAt: new Date().toString(),
    });
  };

  // ========== dev clear functions ==========
  const onClickClearContactApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      verificationConsentGiven: '',
      verificationConsentGivenAt: '',
      emailIsVerified: '',
      emailIsVerifiedAt: '',
      phoneIsSmsVerified: '',
      phoneIsSmsVerifiedAt: '',
      phoneIsVoiceVerified: '',
      phoneIsVoiceVerifiedAt: '',
    });
  };

  const onClickClearLicenseApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      addressConfirmed: '',
      address: '',
      licenseSelfieVerified: '',
      licenseSelfieVerifiedAt: '',
      licenseSelfieVerifiedURL: '',
      licenseSelfieData: '',
      licenseSelfieError: '',
    });
  };
  const onClickClearBankingApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      bankAccountAdded: '',
      bankAccountAddedAt: '',
      bankAccountError: '',
      bankSkipped: false,
      bank: '',
      bankAccountIframe: '',
    });
  };

  const onClickClearKBAApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      kbaPassed: '',
      kbaPassedAt: '',
      kbaError: '',
      kba: '',
      kbaFullResponse: '', // no
      kbaDecisionResponse: '',
      kbaQuestionAnswers: '',
      stageOneVerified: false,
      stageOneVerifiedAt: '',
    });
  };
  const onClickClearAll = () => {
    onClickClearContactApproval();
    onClickClearLicenseApproval();
    onClickClearBankingApproval();
    onClickClearKBAApproval();
  };

  // ========== verification actions ==========
  const acceptConsent = async () => {
    // on first consent screen
    await updateDoc(userRef, {
      verificationConsentGiven: true,
      verificationConsentGivenAt: new Date().toString(),
    });
  };
  const onClickChangeEmail = async () => {
    const newEmail = await window.prompt(t('enterANewEmail'));
    // console.log(newEmail);

    if (!newEmail) return;

    function isValidEmail(email) {
      // Regular expression for validating an email
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }

    if (!isValidEmail(newEmail)) {
      return window.alert('Invalid email');
    }

    if (!window.confirm(t('changeEmailAreYouSure', { newEmail }))) {
      return;
    }

    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        // Email updated!
        // setFormData(prevState => ({
        //   ...prevState,
        //   email: newEmail,
        // }));
        updateDoc(userRef, {
          email: newEmail,
        });
        toast({
          title: t('emailUpdated'),
          status: 'info',
        });
      })
      .catch(error => {
        // An error occurred
        // console.log(error?.code);
        toast({
          title: t('couldNotUpdateEmail'),
          // description: JSON.stringify(error),
          description: error.code,
          status: 'error',
          isClosable: true,
        });
      });
  };

  const onClickChangeCell = async () => {
    const newCell = await window.prompt(t('enterNewCellDoNotAddPlusOne'));
    // console.log(newEmail);

    if (!newCell) return;

    function isValidNumber(input) {
      // Regular expression: Must be a 10-digit number not starting with 0 or 1
      const regex = /^[2-9]\d{9}$/;
      return regex.test(input);
  }
    if (!isValidNumber(newCell)) {
      return window.alert('Invalid 10 digit cell phone');
    }

    if (!window.confirm(t('changeCellAreYouSure', { newCell }))) {
      return;
    }

    const check = await checkCell(newCell);
    // console.log(check);

    if (check.reject) {
      toast({
        title: t('cannotUpdateCell') + check?.rejectionReason,
        status: 'warning',
      });
      return;
    }

    updateDoc(userRef, {
      cell: newCell,
    });
    toast({
      title: t('cellUpdated'),
      status: 'info',
    });
  };

  const onClickSendEmailPasscode = async () => {
    setEmailVerificationSent(true);
    const response = await sendEmailPasscode(auth.currentUser.uid);

    if (!response.success) {
      setEmailVerificationSent(false);
      toast({
        title: t('couldNotGenerateEmailPasscode'),
        description: t('pleaseTryAgainLater') + response.data?.ErrorMessage,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    toast({
      description: t('emailVerificationSent'),
      isClosable: true,
    });
  };

  const onChangeEmailPasscode = async e => {
    if (e.length === 4) {
      // check e against emailPasscode
      if (Number(e) === Number(emailPasscode)) {
        // if ok, change email verified
        await updateDoc(userRef, {
          emailIsVerified: true,
          emailIsVerifiedAt: new Date().toString(),
        });
        toast({
          description: t('passcodeAcceptedEmailVerified'),
          status: 'info',
        });
      } else {
        // if not ok, show toast
        toast({
          title: t('incorrectPasscode'),
          description: t('pleaseVerifyYourEmail'),
          status: 'error',
          isClosable: true,
        });
      }
    }
  };

  const sendSMSVerification = async () => {
    try {
      setSendSmsPINText(t('sending'));
      const response = await fetch(TWILIO_START_VERIFY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: '+1' + cell,
          channel: 'sms', // or call
          locale: 'en',
        }),
      });

      const json = await response.json();

      if (response.status === 429) {
        setSendSmsPINText('');
        toast({
          description: t('youHaveAttemptedToVerifyTooManyTimes'),
          status: 'error',
          isClosable: true,
        });
      } else if (response.status >= 400) {
        setSendSmsPINText(json.error);
      } else {
        // modal.style.display = "block";
        if (json.success) {
          setSendSmsPINText(t('verificationCodeSentEnterBelow'));
          setSmsPINSent(true);
        } else {
          setSendSmsPINText(json.error);
        }
      }
    } catch (error) {
      // console.error(error);
      setSendSmsPINText('');
      toast({
        description: t('somethingWentWrongWhileSendingTheCode'),
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChangeSmsPIN = async e => {
    // console.log(e);
    if (e.length === 4) {
      setFetchingSmsPIN(true);
      setSendSmsPINText(t('checkingCode'));

      // api call
      const data = {
        phoneNumber: '+1' + cell,
        otp: e,
      };

      try {
        // sends the value of both the phone number and the user-submitted OTP to the app’s backend endpoint, /verify-otp
        const response = await fetch(TWILIO_VERIFY_OTP, {
          method: 'POST',
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          setFetchingSmsPIN(false);
          setSendSmsPINText('');
          toast({
            description: 'Verification error: ' + response.statusText,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        // receive a response from the POST request
        const check = await response.json();

        // const text = response.ok ? check.status : response.statusText;
        // console.log('text', text); // pending or approved

        if (check.status === 'approved') {
          // if success, show message, change status in db
          // setFormData(prevState => ({
          //   ...prevState,
          //   phoneIsSmsVerified: true,
          //   phoneIsSmsVerifiedAt: new Date().toString(),
          // }));

          await updateDoc(userRef, {
            phoneIsSmsVerified: true,
            phoneIsSmsVerifiedAt: new Date().toString(),
          });
          setFetchingSmsPIN(false);
          setSendSmsPINText('');
          toast({
            description: t('smsVerificationComplete'),
            status: 'info',
            isClosable: true,
          });
        } else {
          // if fail, show reset button to remove text and fetching false
          setFetchingSmsPIN(false);
          setSendSmsPINText(t('incorrectPasscode'));
        }
      } catch (e) {
        setFetchingSmsPIN(false);
        setSendSmsPINText('');
        toast({
          description: t('verificationError') + JSON.stringify(e),
          status: 'error',
          isClosable: true,
        });
        console.log(JSON.stringify(e));
      }
    }
  };

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const sendErrorComments = e => {
    // pull id from button
    const comments = formData[e.target.id];
    updateDoc(userRef, {
      [e.target.id]: comments,
    });

    // send notice to admin
    sendEmailToAdmin(
      'verificationError',
      `${firstName} ${lastName} - ${dataContext.userData?.uid}`
    );
  };

  const generateLicenseSelfieCheck = async () => {
    setFetchingLicenseURL(true);
    const response = await generateLicenseSelfieCheckURL(cell);
    // console.log('license response', response);
    if (!response.success) {
      toast({
        title: t('couldNotGenerateVerification'),
        description: t('pleaseTryAgainLater'),
        status: 'error',
        isClosable: true,
      });
      setFetchingLicenseURL(false);
      return;
    }

    await updateDoc(userRef, {
      licenseSelfieVerifiedURL: response.data.url,
    });
    setFetchingLicenseURL(false);
  };

  const onChangeAddressComponent = e => {
    updateDoc(userRef, {
      address: {
        ...address,
        [e.target.id]: e.target.value,
      },
    });
  };

  const onClickConfirmAddress = () => {
    updateDoc(userRef, {
      addressConfirmed: true,
    });
  };

  const generateBankingIframe = async () => {
    setFetchingBankingIframe(true);
    const url = BANKING_IFRAME + '&clientuserid=' + uid;
    // update database
    await updateDoc(userRef, {
      bankAccountIframe: url,
    });
    setFetchingBankingIframe(false);
  };

  const onClickSkipBanking = async () => {
    await updateDoc(userRef, {
      bankSkipped: true,
    });
  };

  const finalContinue = async () => {
    // after all checks done, verify stage one and submit stage two
    await updateDoc(userRef, {
      stageOneVerified: true,
      stageOneVerifiedAt: new Date().toString(),
    });
    // have server perform final stage2 verifications
    stageTwoVerifyUser(uid, userData?.address?.postal);
  };

  // ========== render functions ==========
  const renderAddress = () => {
    let canConfirm = false;
    if (
      address.civic &&
      address.street &&
      address.city &&
      address.province &&
      address.province.length === 2 &&
      address.postal
    ) {
      canConfirm = true;
    }

    let warning;

    if (
      address?.province &&
      !eligibleWarrantyProvinces.includes(address?.province.toLowerCase())
    ) {
      // neither eligible province
      warning = (
        <>
          <Alert
            status="warning"
            variant="left-accent"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            mt={4}
          >
            <AlertIcon mr={0} />
            <AlertTitle mt={1} mb={1} fontSize="lg">
              {t('pleaseNote')}
            </AlertTitle>
            <AlertDescription maxWidth="lg">
              {t('onlyAvailableOntarioAndQuebec')}
            </AlertDescription>
          </Alert>
        </>
      );
    }

    return (
      <div>
        <Stack direction="row" w="100%" mt={2}>
          <Box flex="1" color={!address?.civic && 'red'}>
            {t('civic')}
          </Box>
          <Box flex="2" color={!address?.street && 'red'}>
            {t('street')}
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.civic || ''}
                isReadOnly={addressConfirmed}
                id="civic"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="2">
            <InputGroup>
              <Input
                type="text"
                value={address?.street || ''}
                isReadOnly={addressConfirmed}
                id="street"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>
        {!addressConfirmed && (
          <>
            <Stack direction="row" w="100%">
              <Box flex="1" color="gray">
                {t('aptSuite')}
              </Box>
              <Box flex="2"></Box>
            </Stack>
            <Stack direction="row" w="100%">
              <Box flex="1">
                <Input
                  type="text"
                  value={address?.apt || ''}
                  id="apt"
                  onChange={onChangeAddressComponent}
                />
              </Box>
              <Box flex="2"></Box>
            </Stack>
          </>
        )}

        <Box flex="1" color={!address?.city && 'red'} mt={2}>
          {t('city')}
        </Box>

        <Box flex="1">
          <InputGroup>
            <Input
              type="text"
              value={address?.city || ''}
              id="city"
              _focus={
                addressConfirmed && {
                  borderColor: 'inherit',
                }
              }
              isReadOnly={addressConfirmed}
              onChange={onChangeAddressComponent}
            />
            {addressConfirmed && (
              <InputRightElement children={<BsLock color="lightgrey" />} />
            )}
          </InputGroup>
        </Box>

        <Stack direction="row" w="100%" mt={2}>
          <Box
            flex="1"
            color={
              (!address?.province || address?.province.length !== 2) && 'red'
            }
          >
            {t('province')}
          </Box>
          <Box flex="1" color={!address?.postal && 'red'}>
            {t('postal')}
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              {address?.province.length === 2 ? (
                <Input
                  type="text"
                  value={address?.province.toUpperCase() || ''}
                  isReadOnly={true}
                  id="province"
                  variant="flushed"
                  _focus={{
                    borderColor: 'inherit',
                  }}
                />
              ) : (
                <Select
                  id="province"
                  value={address?.province || ''}
                  onChange={onChangeAddressComponent}
                  disabled={addressConfirmed}
                  _focus={
                    addressConfirmed && {
                      borderColor: 'inherit',
                    }
                  }
                >
                  <option value="">Select</option>
                  {allProvinces.map((prov, i) => (
                    <option key={i} value={Object.values(prov)}>
                      {Object.values(prov).map(value => value.toUpperCase())} -{' '}
                      {Object.keys(prov)}
                    </option>
                  ))}
                </Select>
              )}
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.postal || ''}
                isReadOnly={addressConfirmed}
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                id="postal"
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>

        {warning}

        {!addressConfirmed && (
          <Button
            mt={10}
            w="full"
            onClick={onClickConfirmAddress}
            disabled={!canConfirm}
            colorScheme="primary"
            // variant="outline"
          >
            {t('confirmAddress')}
          </Button>
        )}
      </div>
    );
  };

  const renderTab1 = () => {
    return (
      <Flex justify={'center'}>
        <Stack p={2} spacing={2} w="100%">
          {!emailIsVerified && !phoneIsSmsVerified && (
            <Heading size="md" mb={2}>
              {t('emailAndSMSVerify')}
            </Heading>
          )}

          <Card shadow="lg" variant="outline">
            <CardBody>
              {!emailIsVerified ? (
                <>
                  {!emailVerificationSent ? (
                    <div>
                      <Text
                        textAlign="center"
                        // fontSize="md"
                      >
                        {t('clickToEmailAVerificationPasscodeTo')} {email}
                      </Text>
                      <br />
                      <Button
                        onClick={onClickSendEmailPasscode}
                        colorScheme="primary"
                        mb={10}
                        w="full"
                      >
                        {t('sendCapital')}
                      </Button>

                      <br />
                      <Button
                        fontWeight="normal"
                        variant="link"
                        onClick={onClickChangeEmail}
                        size="xs"
                      >
                        {t('changeEmail')}
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Text
                        textAlign="center"
                        // fontSize="md"
                      >
                        {t('emailSentTo')} {email},{' '}
                        <span style={{ textDecoration: 'none', color: 'gray' }}>
                          {t('alsoCheckYourSpamFolders')}
                        </span>
                      </Text>
                      <Center my={5}>
                        <Text color="primary.500">{t('enterPasscode')}</Text>
                      </Center>
                      <FormControl mb={5}>
                        <Center>
                          <HStack>
                            <PinInput
                              otp
                              size="lg"
                              onChange={e => onChangeEmailPasscode(e)}
                            >
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                        </Center>
                      </FormControl>

                      <HStack justify="space-between" mt={10}>
                        <Button
                          fontWeight="normal"
                          variant="link"
                          onClick={onClickChangeEmail}
                          size="xs"
                        >
                          {t('changeEmail')}
                        </Button>
                        <Button
                          onClick={onClickSendEmailPasscode}
                          fontWeight="normal"
                          variant="link"
                          size="xs"
                        >
                          {t('resendEmail')}
                        </Button>
                      </HStack>
                    </div>
                  )}
                </>
              ) : (
                <VStack justify="space-between">
                  <Text fontSize="md">
                    {t('emailVerified')}
                    <CheckIcon color="blue.500" ml="3" />
                  </Text>
                  <Text color="gray">
                    {moment(
                      emailIsVerifiedAt,
                      'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                    ).format('MMM D, YYYY - h:mma')}
                  </Text>
                </VStack>
              )}
            </CardBody>
          </Card>
          <br />

          <Card shadow="lg" variant="outline">
            <CardBody>
              {phoneIsSmsVerified ? (
                <>
                  <VStack justify="space-between">
                    <Text fontSize="md">
                      {t('smsVerified')}
                      <CheckIcon color="blue.500" ml="3" />
                    </Text>
                    <Text color="gray">
                      {moment(
                        phoneIsSmsVerifiedAt,
                        'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                      ).format('MMM D, YYYY - h:mma')}
                    </Text>
                  </VStack>
                </>
              ) : (
                <div>
                  <Text
                    textAlign="center"
                    mb={5}
                    // fontSize="md"
                  >
                    {t('clickToReceiveAOneTimePasscodeTo')} {cell}
                  </Text>
                  {sendSmsPINText ? (
                    <Text textAlign="center" color="primary.500">
                      {sendSmsPINText}
                    </Text>
                  ) : (
                    <Button
                      colorScheme="primary"
                      w="full"
                      // variant="outline"
                      onClick={sendSMSVerification}
                      mb={15}
                    >
                      {t('sendCapital')}
                    </Button>
                  )}
                  <br />
                  {fetchingSmsPIN ? (
                    <FormControl mb={5}>
                      <Center>
                        <HStack>
                          <Spinner />
                          <Spinner />
                          <Spinner />
                          <Spinner />
                        </HStack>
                      </Center>
                    </FormControl>
                  ) : (
                    <div>
                      {smsPINSent && (
                        <>
                          <FormControl mb={5}>
                            <Center>
                              <HStack>
                                <PinInput
                                  otp
                                  size="lg"
                                  onChange={e => onChangeSmsPIN(e)}
                                >
                                  <PinInputField />
                                  <PinInputField />
                                  <PinInputField />
                                  <PinInputField />
                                </PinInput>
                              </HStack>
                            </Center>
                          </FormControl>
                        </>
                      )}
                    </div>
                  )}
                  <br />
                  <HStack justify="space-between">
                    <Button
                      variant="link"
                      fontWeight="normal"
                      onClick={onClickChangeCell}
                      size="xs"
                    >
                      {t('changeCell')}
                    </Button>
                    {smsPINSent && (
                      <Button
                        variant="link"
                        fontWeight="normal"
                        onClick={sendSMSVerification}
                        size="xs"
                      >
                        {t('resendSMS')}
                      </Button>
                    )}
                  </HStack>
                </div>
              )}
            </CardBody>
          </Card>
          {tab1Complete && (
            <>
              <br />
              <br />
              <br />
              <Center>
                <Button
                  size="lg"
                  onClick={handleToTab2Click}
                  width="full"
                  colorScheme="primary"
                >
                  {t('next')}
                </Button>
              </Center>
            </>
          )}

          {isDev && (
            <>
              {!tab1Complete ? (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Box p="2" color="lightgrey">
                    {/* <Text>*DEV*</Text> */}
                    {!emailIsVerified && (
                      <Button
                        m="1"
                        onClick={onClickManualApproveEmail}
                        variant="link"
                        color="lightgrey"
                      >
                        approve-email
                      </Button>
                    )}
                    {!phoneIsSmsVerified && (
                      <Button
                        m="1"
                        onClick={onClickManualApproveSMS}
                        variant="link"
                        color="lightgrey"
                      >
                        approve-sms
                      </Button>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    onClick={onClickClearContactApproval}
                    color="lightgray"
                    variant="link"
                  >
                    clear
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </Flex>
    );
  };

  const renderTab2 = () => {
    return (
      <Flex justify={'center'}>
        <Stack p={2} spacing={2} w="100%">
          {/* {!licenseSelfieVerified && (
            <> */}
          {/* <HStack justify="space-between">
                <Heading as="h3" size="lg">
                  2/3 {t('driversLicenseVsSelfie')}
                </Heading>
              </HStack> */}
          {userData.licenseSelfieWarning && (
            <Alert
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon />
              <AlertDescription maxWidth="sm">
                {userData.licenseSelfieWarning}
              </AlertDescription>
            </Alert>
          )}

          {!licenseSelfieVerified ? (
            <>
              {!licenseSelfieVerifiedURL ? (
                <>
                  <Heading size="md">ID Verify</Heading>
                  <Text
                    // textAlign="center"
                    color="primary.500"
                  >
                    {t('letsCaptureYourDriversLicenseAndSelfie')}
                  </Text>
                  <br />

                  {!fetchingLicenseURL ? (
                    <>
                      {/* <Text>
                        {t('theSelfieTakenWillServeAsProfilePicture')}
                      </Text>
                      <br /> */}
                      <Button
                        onClick={generateLicenseSelfieCheck}
                        colorScheme="primary"
                      >
                        {t('continueCapital')}
                      </Button>
                    </>
                  ) : (
                    <>
                      <br />
                      <Center>
                        <br />
                        <Spinner />
                      </Center>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!licenseSelfieError ? (
                    <>
                      <iframe
                        src={licenseSelfieVerifiedURL}
                        title="verify"
                        height="680px"
                        // width="100%"
                      ></iframe>
                      <br />
                      <Button
                        // w="full"
                        // colorScheme="primary"
                        variant="link"
                        size="sm"
                        as="a"
                        href={licenseSelfieVerifiedURL}
                        target="_blank"
                      >
                        Issues? Open in a new window
                        <ExternalLinkIcon mx="2px" />
                      </Button>
                      {/* <Text textAlign="center">
                        {t(
                          'clickContinueToBeginTheVerificationProcessMustBeOnMobile'
                        )}
                      </Text>
                      <br />
                      <Button
                        w="full"
                        colorScheme="primary"
                        variant="outline"
                        as="a"
                        href={licenseSelfieVerifiedURL}
                        target="_blank"
                      >
                        {t('continue')}
                        <ExternalLinkIcon mx="2px" />
                      </Button>

                      <br />
                      <Progress
                        size="sm"
                        isIndeterminate
                        colorScheme="primary"
                      />
                      <br /> */}
                      {/* <Text textAlign="center">
                        {t('onceCompletedPleaseAllowAFewMoments')}
                      </Text> */}
                      {/* <br />
                      <br />
                      <br />
                      <br /> */}
                    </>
                  ) : (
                    <>
                      <Alert
                        status="error"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        height="200px"
                      >
                        <AlertIcon
                          boxSize="40px"
                          // mr={0}
                        />
                        <AlertTitle mt={4} mb={1} fontSize="lg">
                          {t('couldNotProcess')}
                        </AlertTitle>
                        <AlertDescription maxWidth="sm">
                          {licenseSelfieError}
                        </AlertDescription>
                      </Alert>
                      <FormControl mt="2%">
                        <FormLabel m={0}>
                          {t('sendUsYourCommentsAndWellContactYou')}
                        </FormLabel>
                        <Textarea
                          placeholder={t('addComments')}
                          rows={3}
                          id="licenseSelfieErrorComments"
                          value={formData.licenseSelfieErrorComments}
                          onChange={onChange}
                          disabled={licenseSelfieErrorComments}
                        />
                      </FormControl>
                      {!licenseSelfieErrorComments ? (
                        <Button
                          mt={10}
                          w="full"
                          colorScheme="primary"
                          id="licenseSelfieErrorComments"
                          onClick={sendErrorComments}
                        >
                          {t('sendComments')}
                        </Button>
                      ) : (
                        <Center mt={5}>
                          <Text as="i" color="red">
                            {t('thankYouCommentsReceivedWeAreInvestigating')}
                          </Text>
                        </Center>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Card shadow="lg" variant="outline" mb={4}>
                <CardBody>
                  <VStack justify="space-between">
                    <Text fontSize="md">
                      {t('licenseVerified')}
                      <CheckIcon color="blue.500" ml="3" />
                    </Text>
                    <Text color="gray">
                      {moment(
                        licenseSelfieVerifiedAt,
                        'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                      ).format('MMM D, YYYY - h:mma')}
                    </Text>
                  </VStack>
                </CardBody>
              </Card>
              {!addressConfirmed ? (
                <>
                  <Heading size="md">{t('pleaseConfirmYourAddress')}</Heading>

                  {renderAddress()}
                </>
              ) : (
                <>
                  {/* <Divider /> */}
                  {/* <br /> */}
                  <Card shadow="lg" variant="outline" my={4} minH="100px">
                    <CardBody>
                      <VStack>
                        <Text fontSize="md">
                          {t('addressConfirmed')}
                          <CheckIcon color="blue.500" ml="3" />
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                </>
              )}
            </>
          )}
          {tab2Complete && (
            <>
              {/* <Divider /> */}
              {/* <br />
              <Text color="primary.500" mb={5}>
                {t('licenseSectionCompleteNextBanking')}
              </Text> */}
              <br />
              <br />
              <Center>
                <Button
                  // onClick={
                  //   !tab3Complete ? handleToTab3Click : handleToTab1Click
                  // }
                  onClick={handleToTab3Click}
                  width="full"
                  size="lg"
                  colorScheme="primary"
                >
                  {t('next')}
                </Button>
              </Center>
            </>
          )}

          {isDev && (
            <>
              {!tab2Complete ? (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    m="1"
                    color="lightgray"
                    variant="link"
                    // color="red"
                    onClick={onClickManualApproveLicenseSelfie}
                  >
                    approve-license
                  </Button>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    onClick={onClickClearLicenseApproval}
                    color="lightgray"
                    variant="link"
                  >
                    clear
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </Flex>
    );
  };

  const renderTab3Kount = () => {
    return (
      <>
        <Stack>
          {!tab3Complete ? (
            <>
              <CreditQuestionnaire userRef={userRef} userData={userData} />
            </>
          ) : (
            <>
              {/* user should be redirected to final continue */}
              <br />
              <VStack>
                <Text>
                  Identity Verified!
                  <CheckIcon color="blue.500" mx="3" />
                </Text>
                <Text color="gray">
                  {moment(kbaPassedAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                    'MMM D, YYYY - h:mma'
                  )}
                </Text>
              </VStack>
              <br />
              <br />
              <br />
              <Center>
                <Button
                  onClick={finalContinue}
                  width="full"
                  colorScheme="primary"
                >
                  Done
                </Button>
              </Center>
            </>
          )}

          {isDev && (
            <>
              <br />
              <br />
              {kbaError && (
                <Button
                  onClick={onClickClearKBAApproval}
                  color="lightgray"
                  variant="link"
                >
                  clear-KBA
                </Button>
              )}
              <br />
              {!tab3Complete ? (
                <Button
                  onClick={onClickManualApproveKBA}
                  color="lightgrey"
                  variant="link"
                >
                  approve-kba
                </Button>
              ) : (
                <Button
                  onClick={onClickClearKBAApproval}
                  color="lightgray"
                  variant="link"
                >
                  clear-KBA
                </Button>
              )}
            </>
          )}
        </Stack>
      </>
    );
  };

  const renderTab3Banking = () => {
    return (
      <>
        <Flex justify={'center'}>
          <Stack p={2} spacing={2} w="100%">
            {!bankAccountIframe && !tab3Complete && (
              <>
                <HStack justify="space-between">
                  <Heading size="md">{t('connectYourBank')}</Heading>
                  <Text>({t('optional')})</Text>
                </HStack>
                <Text color="primary.500" mb={5}>
                  {t('loginToYourOnlineBankToSetupWallet')}
                </Text>
                <Text>
                  {t('aBalanceInYourEEZEWalletAllowsForFastAndSecurePayments')}
                </Text>
                <Text>
                  {t('completingThisVerificationStepUnlocksEEZEProducts')}
                </Text>
              </>
            )}

            {!bankSkipped ? (
              <>
                {!bankAccountAdded ? (
                  <>
                    {!bankAccountIframe ? (
                      <>
                        {!fetchingBankingIframe ? (
                          <>
                            <br />
                            <Button
                              onClick={generateBankingIframe}
                              colorScheme="primary"
                              // variant="outline"
                            >
                              {t('connectCapital')}
                            </Button>
                          </>
                        ) : (
                          <>
                            <br />
                            <Center>
                              <br />
                              <Spinner />
                            </Center>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {!bankAccountError ? (
                          <>
                            <Iframe
                              url={bankAccountIframe}
                              width="100%"
                              height="800px"
                            />
                          </>
                        ) : (
                          <>
                            <Alert
                              status="error"
                              variant="subtle"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              height="200px"
                            >
                              <AlertIcon boxSize="40px" />
                              <AlertTitle mt={4} mb={1} fontSize="lg">
                                {t('couldNotProcess')}
                              </AlertTitle>
                              <AlertDescription maxWidth="sm">
                                {bankAccountError}
                              </AlertDescription>
                            </Alert>
                            <FormControl mt="2%">
                              <FormLabel m={0}>
                                {t('sendUsYourCommentsAndWellContactYou')}
                              </FormLabel>
                              <Textarea
                                placeholder={t('addComments')}
                                rows={3}
                                id="bankAccountErrorComments"
                                value={formData.bankAccountErrorComments}
                                onChange={onChange}
                                disabled={bankAccountErrorComments}
                              />
                            </FormControl>
                            {!bankAccountErrorComments ? (
                              <Button
                                mt={10}
                                w="full"
                                colorScheme="primary"
                                id="bankAccountErrorComments"
                                onClick={sendErrorComments}
                              >
                                {t('sendComments')}
                              </Button>
                            ) : (
                              <Center mt={5}>
                                <Text as="i" color="red">
                                  {t(
                                    'thankYouCommentsReceivedWeAreInvestigating'
                                  )}
                                </Text>
                              </Center>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <br />
                    <Button
                      colorScheme="primary"
                      variant="outline"
                      my={5}
                      rightIcon={<BsChevronRight />}
                      onClick={onClickSkipBanking}
                    >
                      {t('skipForNow')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Card shadow="lg" variant="outline" mb={4}>
                      <CardBody>
                        <VStack justify="space-between">
                          <Text fontSize="md">
                            {t('walletCreated')}
                            <CheckIcon color="blue.500" ml="3" />
                          </Text>
                          <Text color="gray">
                            {moment(
                              bankAccountAddedAt,
                              'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                            ).format('MMM D, YYYY - h:mma')}
                          </Text>
                        </VStack>
                      </CardBody>
                    </Card>

                    <br />
                    <br />
                    <Center>
                      <Button
                        onClick={finalContinue}
                        width="full"
                        size="lg"
                        colorScheme="primary"
                      >
                        {t('done')}
                      </Button>
                    </Center>
                  </>
                )}
              </>
            ) : (
              <>
                <Text>{t('bankingSectionSkippedWalletNotCreated')}</Text>

                <br />
                <br />
                <Center>
                  <Button
                    onClick={finalContinue}
                    width="full"
                    size="lg"
                    colorScheme="primary"
                  >
                    {t('done')}
                  </Button>
                </Center>
              </>
            )}
            {isDev && (
              <>
                {!tab3Complete ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button
                      onClick={onClickManualApproveBanking}
                      color="lightgrey"
                      variant="link"
                    >
                      approve-banking
                    </Button>
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button
                      onClick={onClickClearBankingApproval}
                      color="lightgray"
                      variant="link"
                    >
                      clear-banking
                    </Button>
                  </>
                )}
              </>
            )}
          </Stack>
        </Flex>
      </>
    );
  };

  if (stageTwoWarnings) {
    return (
      <>
        <Header />
        <Box p={2} minW={'400px'}>
          <Heading size="md" mb={3}>
            {t('verification')}
          </Heading>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t('verificationErrors')}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {Array.isArray(stageTwoWarnings) ? (
                <>
                  {stageTwoWarnings?.map((warning, i) => (
                    <Text key={i}>{warning}</Text>
                  ))}
                </>
              ) : (
                <Text>{stageTwoWarnings}</Text>
              )}
            </AlertDescription>
          </Alert>
          <FormControl mt="2%">
            <FormLabel m={0}>
              {t('sendUsYourCommentsAndWellContactYouToResolve')}
            </FormLabel>
            <Textarea
              placeholder={t('addComments')}
              rows={3}
              id="stageTwoWarningsComments"
              value={formData.stageTwoWarningsComments}
              onChange={onChange}
              disabled={stageTwoWarningsComments}
            />
          </FormControl>
          {!stageTwoWarningsComments ? (
            <Button
              mt={10}
              w="full"
              colorScheme="primary"
              id="stageTwoWarningsComments"
              onClick={sendErrorComments}
            >
              {t('sendComments')}
            </Button>
          ) : (
            <Center mt={5}>
              <Text as="i" color="red">
                {t('thankYouCommentsReceivedWeAreInvestigating')}
              </Text>
            </Center>
          )}
        </Box>
      </>
    );
  }

  if (stageOneVerified) {
    return (
      <>
        <Header />
        <AppBody>
          {!stageTwoWarnings && (
            <>
              <Box>
                <Center>
                  <Image
                    src={checkMark}
                    alt="Check"
                    boxSize="25%"
                    maxWidth="200px"
                    objectFit="cover"
                  />
                </Center>
              </Box>
              <Alert
                status="success"
                bgColor="white"
                borderWidth="1"
                pt={0}
                borderColor="primary.500"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="300px"
              >
                <AlertTitle mb={1} fontSize="lg">
                  {t('congratulations')}
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  <Text>{t('youvePassedThePreliminaryEEZEChecks')}</Text>
                  <Text my={5}>{t('EEZEWillPerformAFinalVerification')}</Text>
                  <Text>{t('inTheMeantimeContinueToUseEEZE')}</Text>
                </AlertDescription>
              </Alert>
              {showUserBadge ? (
                <Center>
                  <Button
                    onClick={() => navigate('/user/' + userData.uid)}
                    colorScheme="primary"
                    size="lg"
                    w="full"
                    m="5"
                    leftIcon={<BsShieldShaded />}
                  >
                    View EEZE Verified Badge
                  </Button>
                </Center>
              ) : (
                <Center>
                  <Button
                    onClick={() => navigate('/')}
                    colorScheme="primary"
                    size="lg"
                    w="full"
                    m="5"
                    leftIcon={<BsHouse />}
                  >
                    {t('dashboard')}
                  </Button>
                </Center>
              )}
            </>
          )}

          {stageTwoWarnings && (
            <>
              <Alert
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
              >
                <AlertIcon boxSize="40px" />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  {t('verificationErrors')}
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  {stageTwoWarnings?.map((warning, i) => (
                    <Text key={i}>{warning}</Text>
                  ))}
                </AlertDescription>
              </Alert>
              <FormControl mt="2%">
                <FormLabel m={0}>
                  {t('sendUsYourCommentsAndWellContactYouToResolve')}
                </FormLabel>
                <Textarea
                  placeholder={t('addComments')}
                  rows={3}
                  id="stageTwoWarningsComments"
                  value={formData.stageTwoWarningsComments}
                  onChange={onChange}
                  disabled={stageTwoWarningsComments}
                />
              </FormControl>
              {!stageTwoWarningsComments ? (
                <Button
                  bgColor="black"
                  color="white"
                  id="stageTwoWarningsComments"
                  onClick={sendErrorComments}
                >
                  {t('sendComments')}
                </Button>
              ) : (
                <Center>
                  <Text as="i" color="red">
                    {t('thankYouCommentsReceivedWeAreInvestigating')}
                  </Text>
                </Center>
              )}
            </>
          )}

          {isDev && (
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Button
                onClick={onClickClearAll}
                color="lightgray"
                variant="link"
              >
                clear-all
              </Button>
            </>
          )}
        </AppBody>
      </>
    );
  }

  if (!verificationConsentGiven) {
    return (
      <>
        <Header />
        <AppBody>
          <Heading size="md" mb={3} textAlign="center">
            {t('verification')}
          </Heading>
          <Stepper size="lg" index={null} mx={5}>
            {steps.map((step, index) => (
              <Step key={null}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <>
            <Center>
              <Text fontSize="xl" mt={3}>
                Consent
              </Text>
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} my={4} />

            <Text fontSize="sm" mx={6} my={2}>
              By accepting below, you give express consent to EEZE, or an
              authorized agent of EEZE, to collect and use your personal
              information required only to verify your identity and provide you
              with EEZE transaction services or financial products.
            </Text>
            {kountInsteadOfBanking && (
              <Text mx={6} fontSize="sm" my={4}>
                You also consent to EEZE or its agents to authenticate your
                identity by accessing your credit file.{' '}
                <b>
                  Please note that this process will not impact your credit
                  score.
                </b>
              </Text>
            )}
            <Text mx={6} my={4}>
              <a href={LINK.privacyUrl} target="_blank" rel="noreferrer">
                For more detailed information, please review our{' '}
                {t('privacyPolicy')}
              </a>
              <ExternalLinkIcon ml="5px" color="primary.500" />
            </Text>
            <Divider borderColor="primary.500" borderWidth={1} my={4} />
            <Flex justify="center">
              <Checkbox
                value="insuranceConsent"
                p={2}
                colorScheme="primary"
                isChecked={consentIsChecked}
                onChange={e => {
                  setConsentIsChecked(!consentIsChecked);
                }}
              >
                <Text>Click here to accept</Text>
              </Checkbox>
            </Flex>

            <Button
              disabled={!consentIsChecked}
              w="full"
              size="lg"
              mt={10}
              colorScheme="primary"
              onClick={acceptConsent}
            >
              Continue
            </Button>
          </>
        </AppBody>
      </>
    );
  }
  return (
    <>
      <Header />
      <AppBody>
        <Heading size="md" mb={3} textAlign="center">
          {t('verification')} {(tabIndex || 0) + 1}/3
        </Heading>

        <Stepper size="lg" index={tabIndex || 0} mx={5}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>

        <Tabs
          isFitted
          variant="unstyled"
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab ref={tab1Ref} isDisabled={isDev ? false : true}></Tab>
            <Tab ref={tab2Ref} isDisabled={isDev ? false : true}></Tab>
            <Tab ref={tab3Ref} isDisabled={isDev ? false : true}></Tab>
          </TabList>
          <TabPanels p={0} m={0}>
            <TabPanel p={0} m={0}>
              {renderTab1()}
            </TabPanel>
            <TabPanel p={0} m={0}>
              {renderTab2()}
            </TabPanel>
            <TabPanel p={0} m={0}>
              {kountInsteadOfBanking ? renderTab3Kount() : renderTab3Banking()}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </AppBody>
    </>
  );
}

export default Verify;
