import React from 'react';
import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import sendSMSLink from '../services/sendSMSSignup';
import checkMark from '../assets/animat-checkmark.gif';
import { db, isDev, masterSafetyPromotionEnabled } from '../constants';
import {
  Box,
  Flex,
  Fade,
  Button,
  Badge,
  Card,
  VStack,
  CardBody,
  Image,
  HStack,
  Heading,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Center,
  Input,
  Text,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { BsChevronRight } from 'react-icons/bs';
import { useToast } from '@chakra-ui/react';

// import { ReactComponent as PlansImage } from '../assets/svg/illustration-select.svg';
// import OAuth from '../components/OAuth';
import logoImage from '../assets/shield256.png';
import fullLogo from '../assets/logo.png';
import { LINK } from '../constants';
// import qrCode from '../assets/eeze-co-start.png';
import SwitchLanguage from '../components/SwitchLanguage';
import WarrantySplash from '../components/WarrantySplash';

// Assets
// import signUpImage from '../assets/signUpImage.png';

function Start(props) {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  // const params = useParams();
  const [queryParameters] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const pathSearch = location?.pathname + location?.search;
  const currentUrl = `https://${isDev ? 'dev-' : ''}app.eeze.co` + pathSearch;
  const encodedCurrentUrl = encodeURIComponent(currentUrl);
  // console.log(location?.search);
  const qrUrl = `https://qr.rebrandly.com/v1/qrcode?shortUrl=${encodedCurrentUrl}&source=com.rebrandly.app&size=512`;

  const toast = useToast();
  const [continueAnyways, setContinueAnyways] = useState(
    queryParameters.get('desk') === 'y' ? true : true
  );
  // uncomment to have desktop warning screen show
  // const [continueAnyways, setContinueAnyways] = useState(
  //   queryParameters.get('desk') === 'y' ? true : false
  // );
  // in dev, i may need to refresh the page for recaptcha to show, so need to bypass wait screen
  const [refPartner, setRefPartner] = useState();
  // const [selectedPartner, setSelectedPartner] = useState();
  // const [allPartners, setAllPartners] = useState();
  const [logoIsOpen, setLogoIsOpen] = useState(false);

  const [sendingLink, setSendingLink] = useState(false);
  const [sendLinkSuccess, setSendLinkSuccess] = useState(false);
  const [formData, setFormData] = useState({
    cell: queryParameters.get('c') || '',
  });
  const { cell } = formData;

  // keep params if any, for redirect
  // could be ref, aref, a, iref, i, uid or c
  let ref;
  if (queryParameters.get('ref')) {
    ref = queryParameters.get('ref');
    // ex /?ref=garage1
  }
  let aref;
  if (queryParameters.get('aref')) {
    aref = queryParameters.get('aref');
    // ex /?aref=affiliate1
  }
  if (queryParameters.get('a')) {
    aref = queryParameters.get('a');
    // ex /?a=affiliate1
  }
  let iref;
  if (queryParameters.get('iref')) {
    iref = queryParameters.get('iref');
    // ex /?iref=influencer1
  }
  if (queryParameters.get('i')) {
    iref = queryParameters.get('i');
    // ex /?i=influencer1
  }
  const existingParams = location?.search || '';

  useEffect(() => {
    const fetchAllPartners = async () => {
      let partnerSlug;

      try {
        if (ref) {
          partnerSlug = ref;
        }
        if (aref) {
          partnerSlug = aref;
        }
        if (iref) {
          partnerSlug = iref;
        }

        // get all partners
        const colRef3 = collection(db, 'partners');
        const docsSnap3 = await getDocs(colRef3);

        // const allPartnersToSave = [];

        docsSnap3.forEach(partner => {
          if (!partner.data()) return;
          if (!partner.data().approved) return;
          // do not show name or logo if influencer
          if (partner.data().type === 'influencer') return;

          const partnerToSave = {
            businessName: partner.data().businessName,
            label:
              partner.data().businessName +
              ' (' +
              partner.data().address.city +
              ')',
            refSlug: partner.data().refSlug,
            value: partner.data().refSlug,
            safetyPromotionEnabled: partner.data().safetyPromotionEnabled,
            type: partner.data().type,
            photoURL: partner.data()?.photoURL ? partner.data()?.photoURL : '',
          };
          // allPartnersToSave.push(partnerToSave);

          // if refslug matches a partner, display
          if (partnerToSave.refSlug === partnerSlug) {
            setRefPartner(partnerToSave);
            setLogoIsOpen(true); // this will fade in logo
          }
        });

        // setAllPartners(allPartnersToSave);
      } catch (error) {
        console.log(error);
      }
    };

    if (props.showPartners) {
      fetchAllPartners();
    }
  }, [props, aref, iref, ref]);

  const sendLinkToPhone = async () => {
    setSendingLink(true);

    const resp = await sendSMSLink(cell, encodeURIComponent(pathSearch));

    if (resp?.success) {
      setSendingLink(false);
      setSendLinkSuccess(true);
    } else {
      setSendingLink(false);
      toast({
        title: t('errorSendingSMSTo') + cell,
        description: resp?.error ? JSON.stringify(resp?.error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onChangeCell = e => {
    // do not allow more than 10 cell phone digits or letters
    if (e.target.value.length > 10) return;
    // no 0 or 1 or letter as first number
    if (e.target.value.length === 1 && e.target.value === '1') return;
    if (e.target.value.length === 1 && e.target.value === '0') return;
    if (isNaN(e.target.value)) return;
    setFormData(prevState => ({
      ...prevState,
      cell: e.target.value,
    }));
  };

  const renderStart = () => {
    return (
      <Flex
        direction="column"
        w="100%"
        background="transparent"
        px="28px"
        mt={5}
      >
        <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <WarrantySplash />
            </ModalBody>
            <ModalFooter>
              <Button
                w="full"
                my={10}
                // variant="outline"
                colorScheme="primary"
                onClick={() => navigate('/warranty-signup' + existingParams)}
              >
                CONTINUE
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <SwitchLanguage />

        {refPartner?.businessName ? (
          <Fade in={logoIsOpen}>
            <Center>
              <Heading fontSize="sm" align="center">
                {refPartner.businessName}
              </Heading>
            </Center>
            <Center>
              {refPartner?.photoURL && (
                <>
                  <Box>
                    <Image
                      src={refPartner.photoURL}
                      h="60px"
                      maxW="200px"
                      objectFit="contain"
                      alt="Company Logo"
                    />
                  </Box>
                </>
              )}
            </Center>
            <Center mt={2}>
              <Text>{t('presents')}</Text>
            </Center>
          </Fade>
        ) : (
          <>
            <Center>
              <Text>Welcome to</Text>
            </Center>
          </>
        )}

        <Center>
          <Image
            src={fullLogo}
            alt="Logo"
            // boxSize="60px"
            maxH="60px"
            objectFit="cover"
            onClick={() => navigate('/start?desk=y')}
          />
        </Center>
        {/* <Divider borderColor="primary.800" my={2} /> */}

        <Center my={2}>
          <Text fontSize="md" as="b" color="primary.500">
            Select Service
          </Text>
        </Center>

        {/* only if enabled */}
        {(refPartner?.safetyPromotionEnabled ||
          masterSafetyPromotionEnabled) && (
          <Card
            mt={2}
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="elevated"
            borderColor="primary.500"
            borderWidth={1}
          >
            {/* <Container> */}

            <Image
              objectFit="cover"
              // p='10px'
              // borderTopLeftRadius='10%'
              // borderBottomLeftRadius='10%'
              maxW={{ base: '100%', sm: '200px' }}
              maxH={{ base: '150px', sm: '200px' }}
              // src="https://eeze.co/wp-content/uploads/2024/01/eeze-launch.jpg"
              src="https://eeze.co/wp-content/uploads/2024/08/garage-partner-inspection.jpeg"
              alt="Safety"
            />
            {/* </Container> */}

            <Stack>
              <CardBody p="10px">
                <HStack justify="space-between">
                  <Heading size="md">Safety Promotion</Heading>
                  <Box>
                    <VStack align="flex-end">

                      <Button
                        variant="link"
                        // mt={1}
                        size="sm"
                        colorScheme="primary"
                        onClick={() =>
                          window.open(LINK.safetyPromoInfoUrl, '_blank')
                        }
                        rightIcon={<ExternalLinkIcon />}
                      >
                        More info
                      </Button>
                      <Badge
                        rounded="full"
                        px="2"
                        fontSize="xs"
                        colorScheme="red"
                        // position='absolute'
                        // top={0}
                      >
                        Limited Time Offer
                      </Badge>
                    </VStack>
                  </Box>
                </HStack>
                <Text py="2">
                  Get a FREE 90-day powertrain warranty by booking a safety inspection
                  with an EEZE-authorized partner
                </Text>
                <Button
                  w="full"
                  colorScheme="primary"
                  onClick={() =>
                    navigate('/safety-promotion-signup' + existingParams)
                  }
                >
                  Signup and Add Vehicle
                  {/* {t('warrantyAndFinancing')} */}
                </Button>
              </CardBody>
            </Stack>
          </Card>
        )}
        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow="hidden"
          variant="elevated"
          mt={5}
          borderColor="primary.500"
          borderWidth={1}
        >
          <Image
            objectFit="cover"
            maxW={{ base: '100%', sm: '200px' }}
            maxH={{ base: '150px', sm: '200px' }}
            src="https://eeze.co/wp-content/uploads/2024/04/eeze-new-platform.jpg"
            alt="Transaction"
          />

          <Stack>
            <CardBody p="10px">
              <HStack justify="space-between">
                <Heading size="md">EEZE Transaction</Heading>

                <Button
                  variant="link"
                  size="sm"
                  colorScheme="primary"
                  onClick={() =>
                    window.open(LINK.eezeTransactionInfoUrl, '_blank')
                  }
                  rightIcon={<ExternalLinkIcon />}
                >
                  More info
                </Button>
              </HStack>
              <Text py="2">
                EEZE Verifies People, Vehicles, & Secures Money Transfers for
                Private Sales, protecting both buyers and sellers
              </Text>
              <Button
                w="full"
                colorScheme="primary"
                onClick={() => navigate('/sign-up' + existingParams)}
              >
                Get Verified with EEZE
              </Button>
            </CardBody>
          </Stack>
        </Card>

        <Card
          mt={5}
          direction={{ base: 'column', sm: 'row' }}
          overflow="hidden"
          variant="elevated"
          borderColor="primary.500"
          borderWidth={1}
        >
          <Image
            objectFit="cover"
            maxW={{ base: '100%', sm: '200px' }}
            maxH={{ base: '150px', sm: '200px' }}
            src="https://eeze.co/wp-content/uploads/2024/01/eeze-extended-warranty-driveway.jpg"
            alt="Warranty"
          />

          <Stack>
            <CardBody p="10px">
              <HStack justify="space-between">
                <Heading size="md">Extended Warranty</Heading>

                <Button
                  variant="link"
                  // mb={3}
                  size="sm"
                  colorScheme="primary"
                  onClick={() => window.open(LINK.warrantyInfoUrl, '_blank')}
                  rightIcon={<ExternalLinkIcon />}
                >
                  More info
                </Button>
              </HStack>
              <Text py="2">
                Get an EEZE warranty without leaving your driveway. Cover
                unexpected repairs beyond the manufacturer’s warranty
              </Text>
              <Button w="full" colorScheme="primary" onClick={onOpen}>
                Purchase an EEZE Warranty
                {/* {t('warrantyAndFinancing')} */}
              </Button>
            </CardBody>
          </Stack>
        </Card>

        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxW="100%"
          mt={5}
        >
          <Text fontWeight="medium">
            {t('alreadyHaveAnAccount')}
            <Link as="span" ms="5px" to={'/login' + existingParams}>
              {t('login')}
            </Link>
          </Text>
        </Flex>
      </Flex>
    );
  };

  if (continueAnyways) {
    return (
      <Center
      // bgGradient='linear(to-r, white, primary.100)'
      // bgGradient='linear(to-r, white, ##FFE1F6)'
      // bg='blackAlpha.50'
      >
        <Flex position="relative" mb="40px">
          <Flex maxW="800px" w="100%" style={{ userSelect: 'none' }}>
            {renderStart()}
          </Flex>
        </Flex>
      </Center>
    );
  }
  return (
    <Flex position="relative" mb="40px">
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="start"
        style={{ userSelect: 'none' }}
        display={{ base: '100%', md: 'none', lg: 'none' }}
      >
        {renderStart()}
      </Flex>

      <Box display={{ base: 'none', md: 'block' }} mx="25%" alignItems="center">
        <Center mt={10}>
          <Image src={logoImage} alt="Logo" boxSize="60px" objectFit="cover" />
        </Center>
        <Center my={5}>
          <Text
            fontSize="xl"
            fontWeight="extrabold"
            textAlign="center"
            color="primary.500"
            lineHeight={1.2}
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            {t('youMayBeViewingThisPageFromADesktop')}
          </Text>
        </Center>

        <Center
          mx={'20%'}
          p={2}
          // borderWidth="1px"
          borderRadius="20px"
          // borderColor="primary.800"
          mb={5}
        >
          <Stack direction="column" align="center">
            <Text fontSize="lg" color="primary.500">
              {t('scanHereToGoTo')}
            </Text>
            <Center>
              <Image boxSize="50%" maxW="300px" alt="qrCode" src={qrUrl} />
            </Center>
            <Text fontSize="sm" color="primary.500" align="center">
              {currentUrl}
            </Text>
          </Stack>
        </Center>
        {!sendLinkSuccess ? (
          <>
            <Center>
              <Text color="primary.500" textAlign="center">
                {t('orEnterYourCellPhoneAndWeWillTextYou')}
              </Text>
            </Center>

            <Center>
              <Box w="300px">
                <InputGroup my={2} borderRadius="10px">
                  <InputLeftAddon children="+1" />
                  <Input
                    fontSize="sm"
                    type="tel"
                    placeholder={t('mobilePhone')}
                    id="cell2"
                    value={cell}
                    onChange={onChangeCell}
                  />
                </InputGroup>
                {cell && cell.length === 10 ? (
                  <Button
                    mt={2}
                    colorScheme="primary"
                    w="full"
                    isLoading={sendingLink}
                    onClick={sendLinkToPhone}
                  >
                    {t('sendLinkToPhone')}
                  </Button>
                ) : (
                  <Button
                    mt={2}
                    colorScheme="primary"
                    w="full"
                    isLoading={sendingLink}
                    onClick={sendLinkToPhone}
                    disabled
                  >
                    {t('sendLinkToPhone')}
                  </Button>
                )}
              </Box>
            </Center>
          </>
        ) : (
          <>
            <Center mt={10}>
              <Image
                src={checkMark}
                alt="Check"
                p={0}
                boxSize="15%"
                maxWidth="100px"
                objectFit="cover"
              />
            </Center>
            <Center>
              <Text textAlign="center">
                {t('linkSentTo')}
                {cell}
              </Text>
            </Center>
          </>
        )}

        <Button
          mt="10%"
          w="full"
          variant="link"
          size="sm"
          onClick={() => setContinueAnyways(true)}
          rightIcon={<BsChevronRight />}
        >
          {t('continueOnThisComputerAnyways')}
        </Button>
      </Box>
    </Flex>
  );
}

export default Start;
